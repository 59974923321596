const checkNumberIfFilled = async (
  val: string,
  length?: number,
  checkLengthOnUndefined: boolean = true
) => {
  if (
    (val === undefined || val.length === 0) &&
    checkLengthOnUndefined === false
  ) {
    return true;
  }

  const parseResult = Number.parseInt(val);
  if (isNaN(parseResult)) {
    throw new Error('Bitte eine gültige Zahl eingeben');
  }

  if (length !== undefined && val.length !== length) {
    throw new Error(`Bitte genau ${length} Stellen eingeben`);
  }

  return true;
};

export { checkNumberIfFilled };
