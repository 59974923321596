import {
  AreaChartOutlined,
  UserOutlined,
  ApiOutlined,
  QuestionCircleFilled
} from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';
import moment from 'moment';
import { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { UserRole } from '../Components/RequireAuthRoute';
import { AuthenticationContext } from '../Provider/AuthenticationProvider';

const { Header, Sider, Content } = Layout;

function HomePage() {
  const authContext = useContext(AuthenticationContext);
  // const dispatch = useAppDispatch();

  const hasRequiredPermission = (role: UserRole) => {
    return (
      authContext.user &&
      authContext.user.permissions &&
      authContext.user?.permissions.includes(role)
    );
  };

  const menu = (
    <Menu triggerSubMenuAction="click">
      <Menu.Item disabled>Version {process.env.REACT_APP_VERSION}</Menu.Item>
      <Menu.Item disabled>
        {hasRequiredPermission(UserRole.admin)
          ? 'Admin'
          : authContext.user?.validTill &&
            `Lizensiert bis ${moment(authContext.user?.validTill).format('L')}`}
      </Menu.Item>
      <Menu.Item disabled>{authContext.user?.email}</Menu.Item>
      <Menu.Divider />
      <Menu.Item danger onClick={authContext.logOut}>
        Log out
      </Menu.Item>
    </Menu>
  );
  return (
    <Layout style={{ height: '100%' }}>
      <Header style={{ height: 'auto', padding: 0 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <div>
            <img
              src="/assets/mhw_logo.png"
              alt="Mainhattan Logo"
              style={{ height: 60 }}
            />
          </div>
          <div>
            <img
              src="/assets/opt2cut_logo.png"
              alt="Mainhattan Logo"
              style={{ height: 40 }}
            />
          </div>
          <Dropdown key="1" overlay={menu} trigger={['click']}>
            <Button type="primary" shape="circle" icon={<UserOutlined />} />
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider collapsed>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['plotter']}>
            <Menu.Item key="plotter" icon={<AreaChartOutlined />}>
              <Link to="plotter">Plotter</Link>
            </Menu.Item>
            <Menu.Item
              key="plotterBeta"
              icon={<QuestionCircleFilled />}
              hidden={!hasRequiredPermission(UserRole.betatester)}
            >
              <Link to="plotter/beta">Plotter BETA</Link>
            </Menu.Item>
            <Menu.Item
              key="user"
              icon={<UserOutlined />}
              hidden={!hasRequiredPermission(UserRole.admin)}
            >
              <Link to="user">User</Link>
            </Menu.Item>
            <Menu.Item
              key="apitoken"
              icon={<ApiOutlined />}
              hidden={!hasRequiredPermission(UserRole.admin)}
            >
              <Link to="apitoken">API Token</Link>
            </Menu.Item>
            {/* <Menu.Item key="3" icon={<UploadOutlined />}>
              nav 3
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Content style={{ overflow: 'auto', padding: '0.5rem' }}>
          <Outlet />
        </Content>
      </Layout>
      {/* <Footer>Footer</Footer> */}
    </Layout>
  );
}

export default HomePage;
