import { Button, Divider, Drawer, Form, Input, InputNumber, Space } from 'antd';
import { FieldData } from 'rc-field-form/lib/interface';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { LocalStorageKeys, SettingsObject } from '../../../AppCommon';

type ComponentProps = {
  isVisible: boolean;
  onCancel: () => void;
  onSave: () => void;
};

const plots = [
  {
    x: 0,
    y: 3,
  },
  {
    x: 1,
    y: 4,
  },
  {
    x: 2,
    y: 5,
  },
  {
    x: 3,
    y: 6,
  },
];

export function SettingsDrawer(props: ComponentProps) {
  const { isVisible, onCancel, onSave } = props;
  const [form] = Form.useForm<SettingsObject>();

  const [settingObject, setSettingsObject] = useState<SettingsObject>();

  useEffect(() => {
    const settingsVal = localStorage.getItem(LocalStorageKeys.settings);
    if (settingsVal === null) {
      return;
    }
    const {
      pointMoveStep,
      axisColor,
      backgroundColor,
      lineColor,
      markerColor,
      selectedMarkerColor,
      markerSize,
      selectedMarkerSize,
      lineWidth,
    } = JSON.parse(settingsVal) as SettingsObject;

    const pointMoveStepValue = pointMoveStep ?? '0.005';
    const markerSizeValue = markerSize ?? '2.0';
    const selectedMarkerSizeValue = selectedMarkerSize ?? '3.0';

    form.setFieldsValue({
      lineColor: lineColor,
      markerColor: markerColor,
      pointMoveStep: pointMoveStepValue,
      backgroundColor: backgroundColor,
      axisColor: axisColor,
      selectedMarkerColor,
      markerSize: markerSizeValue,
      selectedMarkerSize: selectedMarkerSizeValue,
      lineWidth: lineWidth,
    });
    setSettingsObject({
      lineColor: lineColor,
      markerColor: markerColor,
      pointMoveStep: pointMoveStepValue,
      backgroundColor: backgroundColor,
      axisColor: axisColor,
      selectedMarkerColor,
      markerSize: markerSizeValue,
      selectedMarkerSize: selectedMarkerSizeValue,
      lineWidth: lineWidth,
    });
  }, [isVisible]);

  const handleCancel = () => {
    onCancel();
  };
  const handleSave = (val: SettingsObject) => {
    //   * Save in local Storage
    localStorage.setItem(LocalStorageKeys.settings, JSON.stringify(val));

    onSave();
  };

  const handleFieldsChanged = (_: FieldData[], allFields: FieldData[]) => {
    let previewSettings: any = {};
    allFields.forEach((x) => {
      previewSettings[x.name.toString()] = x.value;
    });

    setSettingsObject(previewSettings);
  };

  return (
    <Drawer
      destroyOnClose
      title="Plotter Einstellungen"
      placement="right"
      closable={false}
      //onClose={handleCancel}
      visible={isVisible}
      extra={
        <Space>
          <Button onClick={handleCancel}>Abbrechen</Button>
          <Button onClick={() => form.submit()} type="primary">
            Speichern
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        onFieldsChange={handleFieldsChanged}
        labelCol={{ span: 16 }}
        onFinish={handleSave}
      >
        <Form.Item name="backgroundColor" label="Farbe Hintergrund">
          <Input type={'color'} size="small" />
        </Form.Item>
        <Form.Item name="lineColor" label="Farbe Linie">
          <Input type={'color'} size="small" />
        </Form.Item>
        <Form.Item name="lineWidth" label="Stärke Linien">
          <InputNumber
            size="small"
            min={0.1}
            max={5}
            defaultValue={0.5}
            step="0.1"
            stringMode
          />
        </Form.Item>
        <Form.Item name="markerColor" label="Farbe Messpunkte">
          <Input type={'color'} size="small" />
        </Form.Item>
        <Form.Item name="markerSize" label="Größe Messpunkte">
          <InputNumber
            size="small"
            min={1}
            max={10}
            defaultValue={2}
            step="0.5"
            stringMode
          />
        </Form.Item>
        <Form.Item
          name="selectedMarkerColor"
          label="Farbe ausgewählte Messpunkte"
        >
          <Input type={'color'} size="small" />
        </Form.Item>
        <Form.Item
          name="selectedMarkerSize"
          label="Größe ausgewählte Messpunkte"
        >
          <InputNumber
            size="small"
            min={1}
            max={10}
            defaultValue={2}
            step="0.5"
            stringMode
          />
        </Form.Item>
        <Form.Item name="axisColor" label="Farbe Achsen &amp; Beschriftung">
          <Input type={'color'} size="small" />
        </Form.Item>
        <Plot
          data={[
            {
              x: plots.map((x) => x.x),
              y: plots.map((x) => x.y),
              type: 'scatter',
              mode: 'lines+markers',
              selectedpoints: [1],
              //@ts-ignore
              selected: {
                marker: {
                  color: settingObject?.selectedMarkerColor,
                  size: Number.parseFloat(
                    settingObject?.selectedMarkerSize ?? ''
                  ),
                },
              },
              marker: {
                color: settingObject?.markerColor,
                size: Number.parseFloat(settingObject?.markerSize ?? ''),
              },
              line: {
                color: settingObject?.lineColor,
                width: Number.parseFloat(settingObject?.lineWidth ?? ''),
              },
            },
          ]}
          layout={{
            width: 330,
            height: 300,

            plot_bgcolor: settingObject?.backgroundColor,
            paper_bgcolor: settingObject?.backgroundColor,
            xaxis: {
              color: settingObject?.axisColor,
            },
            yaxis: {
              color: settingObject?.axisColor,
            },
          }}
          config={{
            staticPlot: true,
          }}
        />
        <Divider />
        <Form.Item name="pointMoveStep" label="Wert Punkte verschieben">
          <InputNumber
            size="small"
            min={0}
            max={10}
            defaultValue={0.005}
            step="0.001"
            stringMode
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
