import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal, Radio,
  Select,
  Switch
} from 'antd';
import { useEffect, useState } from 'react';
import { AppPlotPoint } from '../../../Models/AppPlot';

const { TextArea } = Input;
const { Option } = Select;

interface IFormType {
  feed: string;
  filename: string;
  optOrg: string;
  rpm: number;
  turningTool: string;
  wetProcessing: boolean;
  zCorrectionVal: boolean;
}

type ComponentProps = {
  isVisible: boolean;
  onOk: (filename: string, exportRows: string) => void;
  onCancel?: () => void;
  afterClose: () => void;
  zCorrectionExecuted: { executed: boolean; value: number | null };
  extendContourRightExecuted: boolean;
  originalFileName: string;
  plots: AppPlotPoint[];
  header: string[];
  footer: string[];
};

function getCurrentFileType(filename: string) {
  return filename.includes('OPT')
    ? 'opt'
    : filename.includes('ORG')
    ? 'org'
    : filename.includes('TAST')
    ? 'tast'
    : undefined;
}

function modifyFileName(
  originalFileName: string,
  fileTypeName: string,
  zCorrectionExecuted: boolean,
  zCorrectionValue: number | null,
  applyZCorrectionVal: boolean
) {
  let filename = originalFileName.slice();
  const containsType = filename.match(/(OPT|ORG|TAST)/);

  if (containsType === null) {
    filename = `${filename}-${fileTypeName.toUpperCase()}`;
  } else {
    filename = filename.replace(/(OPT|ORG|TAST)/, fileTypeName.toUpperCase());
  }

  if (applyZCorrectionVal && zCorrectionValue && zCorrectionExecuted) {
    const zFilenameVal = Math.abs(zCorrectionValue).toString().replace('.', '');
    filename = `${filename}-${zFilenameVal}`;
  } else {
    const hasCorrectionVal = filename.indexOf('-1', filename.length - 3);
    if (hasCorrectionVal > -1) {
      filename = filename.substring(0, filename.length - 3);
    }
  }

  return filename;
}

/**
 * When extendContourRight was executed the Z value of the feed row must be the same as the Z value of the first plot row
 */
function correctFeedValue(feedRow: string, firstPlot: AppPlotPoint) {
  const feedRowSplit = feedRow.split(' ');

  if (feedRowSplit.length < 2) {
    console.error('The feed row is not correct');
    return;
  }

  const feedZIndex = feedRowSplit[0].indexOf('Z');
  return `${feedRowSplit[0].slice(0, feedZIndex)}Z${firstPlot.z.toFixed(5)} ${
    feedRowSplit[1]
  }`;
}

function correctG00ZValue(baseZValue: number) {
  const newG00ZValue = baseZValue + 5;
  return `G00Z${newG00ZValue.toFixed(5)}`;
}

function getRowIndex(
  rows: string[],
  searchString: string,
  findLast: boolean = false
) {
  if (findLast) {
    let elementIndex = -1;
    for (let index = rows.length - 1; index >= 0; index--) {
      if (rows[index].toLowerCase().includes(searchString)) {
        elementIndex = index;
        break;
      }
    }
    return elementIndex;
  }
  return rows.findIndex((x) => x.toLowerCase().includes(searchString));
}

function getRow(
  rows: string[],
  searchString: string,
  findLast: boolean = false
) {
  if (findLast) {
    let findElement: string | undefined;
    for (let index = rows.length - 1; index >= 0; index--) {
      const match = rows[index].toLowerCase().includes(searchString);
      if (match) {
        findElement = rows[index];
        break;
      }
    }
    return findElement;
  }
  return rows.find((x) => x.toLowerCase().includes(searchString));
}

export function ExportContourModal(props: ComponentProps) {
  const {
    onOk,
    isVisible,
    onCancel,
    afterClose,
    zCorrectionExecuted,
    originalFileName,
    plots,
    header,
    footer,
    extendContourRightExecuted,
  } = props;
  const [form] = Form.useForm<IFormType>();

  const [filePreview, setFilePreview] = useState<string>('');
  const [headerRows, setHeaderRows] = useState<string[]>([]);
  const [footerRows, setFooterRows] = useState<string[]>([]);

  useEffect(() => {
    let headerRowsCopy = [...header];
    let footerRowsCopy = [...footer];

    const fileType = getCurrentFileType(originalFileName) ?? 'opt';

    const filename = modifyFileName(
      originalFileName,
      fileType,
      zCorrectionExecuted.executed,
      zCorrectionExecuted.value,
      zCorrectionExecuted.executed
    );

    // * G00X value must be the same as first G01X of plots [see MAIN-51]
    const firstBodyRow = plots[0];
    const g00XRowIndex = getRowIndex(headerRowsCopy, 'g00x');
    headerRowsCopy.splice(g00XRowIndex, 1, `G00X${firstBodyRow.x.toFixed(5)}`);

    const feedRowIndex = getRowIndex(headerRowsCopy, 'f');
    const feedRow = getRow(headerRowsCopy, 'f');
    const g00ZRowIndex = getRowIndex(headerRowsCopy, 'g00z', true);

    if (feedRow) {
      const newFeedRow = correctFeedValue(feedRow, plots[0]);
      const newG00ZRow = correctG00ZValue(plots[0].z);

      if (newFeedRow) {
        headerRowsCopy.splice(feedRowIndex, 1, newFeedRow);
      }

      headerRowsCopy.splice(g00ZRowIndex, 1, newG00ZRow);
    }

    toggleWetProcessing(true, headerRowsCopy, footerRowsCopy);

    form.setFieldsValue({
      feed: '0.20',
      filename,
      optOrg: fileType,
      rpm: 300,
      turningTool: 't101',
      wetProcessing: true,
      zCorrectionVal: zCorrectionExecuted.executed,
    });

    setHeaderRows(headerRowsCopy);
    setFooterRows(footerRowsCopy);
  }, [
    extendContourRightExecuted,
    footer,
    form,
    header,
    originalFileName,
    plots,
    zCorrectionExecuted.executed,
    zCorrectionExecuted.value,
  ]);

  useEffect(() => {
    let plotText: string = '';

    plotText = headerRows.reduce((prev, curr) => {
      return `${prev}${curr};\n`;
    }, plotText);

    plotText = plots.reduce((prev, curr) => {
      const xVal: string = curr.x.toFixed(5);
      const zVal: string = curr.z.toFixed(5);
      return `${prev}G01X${xVal}Z${zVal};\n`;
    }, plotText);

    plotText = footerRows.reduce((prev, curr) => {
      return `${prev}${curr};\n`;
    }, plotText);

    setFilePreview(plotText);
  }, [plots, headerRows, footerRows]);

  const handleFormValuesChanged = (
    changedVal: { [key: string]: string },
    values: IFormType
  ) => {
    let headerRowsCopy = [...headerRows];
    let footerRowsCopy = [...footerRows];

    // * Change filename
    if (!Object.keys(changedVal).includes('filename')) {
      const filename = modifyFileName(
        originalFileName,
        values.optOrg,
        zCorrectionExecuted.executed,
        zCorrectionExecuted.value,
        values.zCorrectionVal
      );
      form.setFieldsValue({
        filename,
      });
    }

    // * Set turning tool
    const turningToolRowIndex = headerRows.findIndex((x) =>
      x.toLowerCase().startsWith('t')
    );
    headerRowsCopy.splice(
      turningToolRowIndex,
      1,
      values.turningTool.toUpperCase()
    );

    // * Set RPM value
    const rpmRowIndex = headerRows.findIndex((x) =>
      x.toLowerCase().startsWith('m03s')
    );
    headerRowsCopy.splice(rpmRowIndex, 1, `m03s${values.rpm}`.toUpperCase());

    // * Set feed
    const feedRowIndex = getRowIndex(headerRowsCopy, 'f');
    const feedRow = getRow(headerRowsCopy, 'f');

    if (feedRow) {
      const feedRowFIndex = feedRow.toLowerCase().search('f');
      const currentFString = feedRow.substring(feedRowFIndex);
      const feedNumber = Number.parseFloat(values.feed).toFixed(5);
      const newFeedRow = feedRow.replace(currentFString, `F${feedNumber}`);

      headerRowsCopy.splice(feedRowIndex, 1, newFeedRow);
    }

    toggleWetProcessing(values.wetProcessing, headerRowsCopy, footerRowsCopy);

    setHeaderRows(headerRowsCopy);
    setFooterRows(footerRowsCopy);
  };

  const toggleWetProcessing = (
    turnOn: boolean,
    headerRows: string[],
    footerRows: string[]
  ) => {
    // * Set wet processing
    const wetProcessingStartRowIndex = headerRows.findIndex((x) =>
      x.toLowerCase().includes('m08')
    );
    const wetProcessingStopRowIndex = footerRows.findIndex((x) =>
      x.toLowerCase().includes('m09')
    );

    if (turnOn) {
      if (
        wetProcessingStartRowIndex === -1 ||
        wetProcessingStopRowIndex === -1
      ) {
        // * Insert one before feed row -> wet processing on
        if (wetProcessingStartRowIndex === -1) {
          headerRows.splice(headerRows.length - 1, 0, 'm08'.toUpperCase());
        }

        // * Insert a first in footer row -> wet processing off
        if (wetProcessingStopRowIndex === -1) {
          footerRows.unshift('m09'.toUpperCase());
        }
      }
    } else {
      if (wetProcessingStartRowIndex > -1 || wetProcessingStopRowIndex > -1) {
        if (wetProcessingStartRowIndex > -1) {
          headerRows.splice(wetProcessingStartRowIndex, 1);
        }

        if (wetProcessingStopRowIndex > -1) {
          footerRows.splice(wetProcessingStopRowIndex, 1);
        }
      }
    }

    return { headerRows, footerRows };
  };

  return (
    <Modal
      visible={isVisible}
      onOk={() => onOk(form.getFieldValue('filename'), filePreview)}
      onCancel={onCancel}
      afterClose={afterClose}
      okText="OK"
      cancelText="Abbrechen"
      width={600}
      title="Kontur Exportieren"
      closable={false}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 10 }}
        onValuesChange={handleFormValuesChanged}
      >
        {/* Export type */}
        <Form.Item name="optOrg" label="Export Typ">
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="opt">OPT</Radio.Button>
            <Radio.Button value="org">ORG</Radio.Button>
            <Radio.Button value="tast">TAST</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {/* Z-correction val */}
        {zCorrectionExecuted.executed && (
          <Form.Item
            name="zCorrectionVal"
            label={`Z-Korrekturwert anfügen (${zCorrectionExecuted.value})`}
            valuePropName="checked"
          >
            <Checkbox defaultChecked={zCorrectionExecuted.executed} />
          </Form.Item>
        )}
        {/* Turning tool */}
        <Form.Item name="turningTool" label="Drehwerkzeug">
          <Select placeholder="Bitte Drehwerkzeug auswählen">
            <Option value="t101">T101</Option>
            <Option value="t202">T202</Option>
            <Option value="t404">T404</Option>
          </Select>
        </Form.Item>
        {/* RPM */}
        <Form.Item name="rpm" label="Drehzahl" extra="0 - 2000">
          <InputNumber addonAfter="RPM" min={0} max={2000} defaultValue={300} />
        </Form.Item>
        {/* Wet processing */}
        <Form.Item
          name="wetProcessing"
          label="Nassbearbeitung"
          valuePropName="checked"
        >
          <Switch defaultChecked />
        </Form.Item>
        {/* Feed */}
        <Form.Item name="feed" label="Vorschub" extra="F 0.01 - F 9.99">
          <InputNumber
            addonBefore="F"
            min={'0.01'}
            max={'9.99'}
            defaultValue={'0.2'}
            stringMode
            step={'0.01'}
          />
        </Form.Item>
        {/* Filename */}
        <Form.Item
          name="filename"
          label="Dateiname"
          initialValue={originalFileName}
          help={'Dateiname erst zum Schluss manuell bearbeiten'}
          rules={[
            {
              required: true,
              message: 'Bitte Dateinamen angeben',
            },
          ]}
        >
          <Input placeholder="Export Dateiname" />
        </Form.Item>
      </Form>
      <TextArea rows={10} value={filePreview} />
    </Modal>
  );
}
