import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useQueryState = (query: string, initalValue?: string | number) => {
  const navigate = useNavigate();

  const setQuery = useCallback(
    (val: string | number) => {
      const existingQueries = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      const queryString = qs.stringify(
        {
          ...existingQueries,
          [query]: val,
        },
        { skipNulls: true }
      );

      navigate(`${window.location.pathname}?${queryString}`, {
        replace: false,
      });
    },
    [navigate, query]
  );

  useEffect(() => {
    if (initalValue !== undefined) {
      setQuery(initalValue);
    }
  }, [initalValue, setQuery]);

  const getQuery = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })[query];

  return [getQuery, setQuery] as const;
};
