import { Button, Col, Form, Input, notification, Row, Typography } from 'antd';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { LocalStorageKeys } from '../AppCommon';
import { authenticationService } from '../client';
import { AuthenticationContext } from '../Provider/AuthenticationProvider';

const { Title } = Typography;

type FormShape = {
  email: string;
};

function ResetPasswordPage() {
  const authContext = useContext(AuthenticationContext);
  const navigation = useNavigate();
  const [form] = Form.useForm<FormShape>();

  useEffect(() => {
    handleReAuthenticate();
    const remeberedUsername = localStorage.getItem(LocalStorageKeys.username);

    if (remeberedUsername !== null) {
      form.setFieldsValue({
        email: remeberedUsername,
      });
    }

    async function handleReAuthenticate() {
      try {
        const isAuthenticated = await authContext.reAuthenticate();
        if (isAuthenticated) {
          navigation('/app/plotter');
        }
      } catch (error) {}
    }
  }, [authContext, form, navigation]);

  const handleSubmit = async (vals: FormShape) => {
    const { email } = vals;

    try {
      await authenticationService.resetPassword({ email });
      notification['success']({
        message: 'Passwort zurücksetzen',
        description: 'Eine EMail wird an die angegebene Adresse geschickt',
      });
      navigation('/');
    } catch (error) {}
  };

  const handleSubmitFailed = () => {};

  return (
    <>
      <Row justify="center" style={{ paddingTop: 100 }}>
        <Title level={2}>OPT2CUT - Passwort zurücksetzen</Title>
      </Row>
      <Row justify="center">
        <Col span={6}>
          <Form
            action=""
            form={form}
            name="basic"
            onFinish={handleSubmit}
            onFinishFailed={handleSubmitFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Bitte Email eingeben' }]}
            >
              <Input type="email" autoComplete="email" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Passwort anfordern
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default ResetPasswordPage;
