import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Row,
  Typography,
} from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { AxiosError } from 'axios';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { LocalStorageKeys } from '../AppCommon';
import { authenticationService } from '../client';
import { AuthenticationContext } from '../Provider/AuthenticationProvider';

const { Title } = Typography;

type FormShape = {
  email: string;
  password: string;
  remember: boolean;
};

function LoginPage() {
  const authContext = useContext(AuthenticationContext);
  const navigation = useNavigate();
  const [form] = Form.useForm<FormShape>();

  useEffect(() => {
    handleReAuthenticate();
    const remeberedUsername = localStorage.getItem(LocalStorageKeys.username);

    if (remeberedUsername !== null) {
      form.setFieldsValue({
        email: remeberedUsername,
      });
    }

    async function handleReAuthenticate() {
      try {
        const isAuthenticated = await authContext.reAuthenticate();
        if (isAuthenticated) {
          navigation('/app/plotter');
        }
      } catch (error) {}
    }
  }, [authContext, form, navigation]);

  const handleSubmit = async (vals: FormShape) => {
    const { email, password, remember } = vals;

    if (remember) {
      localStorage.setItem(LocalStorageKeys.username, email);
    } else {
      localStorage.removeItem(LocalStorageKeys.username);
    }

    try {
      await authContext.logIn({ email, password });
      navigation('/app/plotter');
    } catch (error) {
      const axiosError = error as AxiosError<{ result: { userId: string } }>;
      const notificationKey = `${Date.now()}`;

      let errorProps: ArgsProps = {
        message: 'Fehler bei der Anmeldung',
        description: 'Es ist ein Fehler aufgetreten',
      };

      if (axiosError.response?.status === 401) {
        errorProps = {
          message: 'Fehler bei der Anmeldung',
          description: 'Bitte Anmeldedaten oder Lizenz Prüfen',
          key: notificationKey,
        };
      } else if (axiosError.response?.status === 403) {
        const sessionUserId = axiosError.response.data.result.userId;
        console.log(axiosError.response.data);
        const closeButton = (
          <Button
            type="primary"
            size="small"
            onClick={async () => {
              await authenticationService.deleteSession({
                userId: sessionUserId,
              });
              notification.close(notificationKey);
            }}
          >
            Jetzt Geräte abmelden
          </Button>
        );

        errorProps = {
          message: 'Unbefugter Anmeldeversuch',
          description: 'Sie sind bereits an einem anderen Gerät angemeldet',
          duration: null,
          key: notificationKey,
          btn: closeButton,
        };
      }

      notification['error'](errorProps);
    }
  };

  const handleSubmitFailed = () => {};

  return (
    <>
      <Row justify="center" style={{ paddingTop: 100 }}>
        <Title level={2}>OPT2CUT - Anmeldung</Title>
      </Row>
      <Row justify="center">
        <Col span={6}>
          <Form
            action=""
            form={form}
            name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            onFinishFailed={handleSubmitFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Bitte Email eingeben' }]}
            >
              <Input type="email" autoComplete="email" />
            </Form.Item>

            <Form.Item
              label="Passwort"
              name="password"
              rules={[{ required: true, message: 'Bitte Passwort eingeben' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Email Adresse merken</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Anmelden
              </Button>
            </Form.Item>
            <Form.Item>
            <Link to="password-reset">Passwort vergessen?</Link>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default LoginPage;
