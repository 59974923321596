import { DeleteOutlined, KeyOutlined } from '@ant-design/icons';
import { IApiToken } from '@ppulwey/opttocut-library';
import {
  Button,
  Col,
  Divider,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';

import moment from 'moment';
import { useEffect } from 'react';
import { ApiTokenService } from '../../client';
import { useQueryState } from '../../Hooks/useQueryState';
import { useService } from '../../Hooks/useService';
const { Title } = Typography;

const ApiToken = () => {
  //const [apiTokens, setApiTokens] = useState<string>();
  const [pageSize, setPageSize] = useQueryState('pagesize', 10);
  const [page, setPage] = useQueryState('page', 1);

  const [apiTokens, apiTokenService] = useService<IApiToken>(ApiTokenService);

  const UserTableColumns: ColumnsType<IApiToken> = [
    {
      title: 'Token',
      dataIndex: 'token',
    },
    {
      title: 'Erstellungsdatum',
      dataIndex: 'createdAt',
      render: (val) => moment(val).utc().format('L'),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="default"
            danger
            icon={<DeleteOutlined />}
            size={'middle'}
            onClick={() => handleDeleteApiKey(record._id!)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    apiTokenService.getManyEntries();
  }, [apiTokenService]);

  const handleCreateApiKey = () => {
    apiTokenService.postEntry({});
  };

  const handleDeleteApiKey = (id: string) => {
    apiTokenService.deleteEntry(id);
  };

  const changePageSize = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <Row>
        <Col>
          <Title level={2}>Benutzerverwaltung</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <Space split={<Divider type="vertical" />}>
            <Tooltip placement="top" title={'Schlüssel generieren'}>
              <Button onClick={handleCreateApiKey} icon={<KeyOutlined />} />
            </Tooltip>
          </Space>
        </Col>
      </Row>
      <Row style={{ paddingTop: 10 }}>
        <Col span={24}>
          {apiTokens.allData && (
            <Table
              columns={UserTableColumns}
              dataSource={apiTokens.allData.result}
              pagination={{
                current: parseInt(page?.toString() ?? '1'),
                pageSize: parseInt(pageSize?.toString() ?? '10'),
                pageSizeOptions: [10, 20, 50, 100],
                onChange: changePageSize,
                total: apiTokens.allData?.totalPages,
                showSizeChanger: true,
                position: ['topLeft'],
              }}
              onChange={(pagination, filter, sorter) => {}}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ApiToken;
