import React, { PropsWithChildren, ReactNode, useContext } from 'react';
import { useLocation, Navigate } from 'react-router';
import { AuthenticationContext } from '../Provider/AuthenticationProvider';

export enum UserRole {
  user = 0,
  admin = 1,
  betatester = 2,
}

export interface IAuthRoute {
  roles?: UserRole;
  children: JSX.Element;
}

const AuthRoute = ({ children, roles }: IAuthRoute) => {
  let auth = useContext(AuthenticationContext);
  let location = useLocation();

  if (auth.user === undefined || auth.user.permissions === undefined) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const hasRequiredRoles =
    roles === undefined
      ? true
      : auth.user && auth.user.permissions!.includes(roles);

  if (!auth.isAuthenticated || !hasRequiredRoles) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthRoute;
