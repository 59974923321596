import { Route, Routes } from 'react-router-dom';
import './App.less';
import AuthRoute, { UserRole } from './Components/RequireAuthRoute';
import ApiToken from './Pages/ApiToken/ApiToken';
import HomePage from './Pages/Home';
import LoginPage from './Pages/Login';
import { PlotterIndexPage } from './Pages/Plotter/PlotterIndex';
import { PlotterView } from './Pages/Plotter/PlotterView';
import { PlotterView as PlotterViewBeta } from './Pages/PlotterBeta/PlotterView';
import ResetPasswordPage from './Pages/ResetPassword';
import { UsersPage } from './Pages/Users';

function App() {
  // useEffect(() => {
  //   const unloadCallback = async (event: any) => {
  //     event.preventDefault();
  //     await authenticationService.logOut({});
  //   };
  //   window.addEventListener('visibilitychange', unloadCallback);
  //   return () => {
  //     window.removeEventListener('visibilitychange', unloadCallback);
  //   };
  // }, []);

  function getRoutes() {
    return (
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/password-reset" element={<ResetPasswordPage />} />
        <Route
          path="/app"
          element={
            <AuthRoute>
              <HomePage />
            </AuthRoute>
          }
        >
          {/* <Route index element={<Dashboard />} /> */}
          <Route path="plotter" element={<PlotterIndexPage />}>
            <Route index element={<PlotterView />} />
            <Route
            path="beta"
            element={
              <AuthRoute roles={UserRole.betatester}>
                <PlotterViewBeta />
              </AuthRoute>
            }
          />
          </Route>
          <Route
            path="user"
            element={
              <AuthRoute roles={UserRole.admin}>
                <UsersPage />
              </AuthRoute>
            }
          />
          <Route
            path="apitoken"
            element={
              <AuthRoute roles={UserRole.admin}>
                <ApiToken />
              </AuthRoute>
            }
          />
        </Route>
      </Routes>
    );
  }

  return <div className="App-Container">{getRoutes()}</div>;
}

export default App;
