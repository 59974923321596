const LocalStorageKeys = {
  settings: 'SETTINGS',
  username: 'USER_NAME',
  accessToken: 'ACCESS_TOKEN',
};

export type SettingsObject = {
  backgroundColor: string;
  lineColor: string;
  lineWidth: string;
  markerColor: string;
  markerSize: string;
  selectedMarkerColor: string;
  selectedMarkerSize: string;
  pointMoveStep: string;
  axisColor: string;
};

export { LocalStorageKeys };
