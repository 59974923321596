import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { AuthLevels, IParams, IUser } from '@ppulwey/opttocut-library';
import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { UserService } from '../../client';
import { getCopy } from '../../utils/getCopy';
import { AddUserModal, AddUserModalFormType } from './AddUserModal';

const { Title } = Typography;

interface IUsersPageProps {}

const UsersPage = ({ children }: PropsWithChildren<IUsersPageProps>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState<IUser[] | null>(null);
  const [showAddUserDialog, setShowAddUserDialog] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number | null>(null);
  const [currentPageSize, setCurrentPageSize] = useState<number | null>(null);

  const [totalEntries, setTotalEntries] = useState<number>(0);

  const [editUser, setEditUser] = useState<IUser | undefined>();

  let urlParams = useRef<Record<string, string>>({});

  const UserTableColumns: ColumnsType<IUser> = [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Berechtigung',
      dataIndex: 'permissions',
      render: (val: number[]) =>
        val
          ? val.map((x) => {
              switch (AuthLevels[x]) {
                case AuthLevels[AuthLevels.admin]:
                  return <Tag color="pink">{AuthLevels[x]}</Tag>;
                case AuthLevels[AuthLevels.user]:
                  return <Tag color="cyan">{AuthLevels[x]}</Tag>;
                case AuthLevels[AuthLevels.betatester]:
                  return <Tag color="yellow">{AuthLevels[x]}</Tag>;
                default:
                  return <Tag color="grey">{AuthLevels[x]}</Tag>;
              }
            })
          : '--',
    },
    {
      title: 'Lizensiert bis',
      dataIndex: 'validTill',
      render: (val) => (val ? moment(val).utc().format('L') : '∞'),
    },
    {
      title: 'Kundennummer',
      dataIndex: 'customerNumber',
    },
    {
      title: 'Firmenname',
      dataIndex: 'companyName',
    },
    {
      title: 'Letzte Anmeldung',
      dataIndex: 'lastLogin',
      render: (val) => (val ? moment(val).format('LLL') : '--'),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="default"
            icon={<EditOutlined />}
            size={'middle'}
            onClick={() => openEditUserDialog(record)}
          />
          <Button
            type="default"
            danger
            icon={<DeleteOutlined />}
            size={'middle'}
            onClick={() => handleDeleteUser(record)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    let pageParam = searchParams.get('page');
    let pageSizeParam = searchParams.get('pagesize');

    let pageNumber = pageParam ? Number.parseInt(pageParam) : 1;
    let pageSizeNumber = pageSizeParam ? Number.parseInt(pageSizeParam) : 10;

    setCurrentPage(pageNumber);
    setCurrentPageSize(pageSizeNumber);
  }, []);

  useEffect(() => {
    let newPageParams: Record<string, string> = {};

    if (currentPage !== null) {
      newPageParams = {
        ...newPageParams,
        page: currentPage.toString(),
      };
    }

    if (currentPageSize !== null) {
      newPageParams = {
        ...newPageParams,
        pagesize: currentPageSize.toString(),
      };
    }

    urlParams.current = newPageParams;
    setSearchParams(urlParams.current);
    getAllUser(currentPageSize ?? 10, currentPage ?? 1);
  }, [currentPage, currentPageSize, setSearchParams]);

  const saveNewUser = async (val: AddUserModalFormType) => {
    console.log(val);
    const userObject: Partial<IUser> = {
      email: val.email,
      permissions: val.permissions,
      companyName: val.companyName,
      customerNumber: val.customerNumber,
      validTill:
        val.validTill === undefined
          ? undefined
          : moment(val.validTill).utc(false).endOf('day').toDate(),
    };

    try {
      if (editUser !== undefined) {
        const cloneEditUser = getCopy<IUser>(editUser);
        Object.assign(cloneEditUser, userObject);
        await UserService.patch!(editUser._id!, cloneEditUser);
      } else {
        await UserService.post!(userObject);

        notification['success']({
          message: 'Nutzer wurde angelegt',
          description: 'Der Nutzer wurde erfolgreich angelegt',
        });
      }

      getAllUser(currentPageSize ?? 10, currentPage ?? 1);
      setShowAddUserDialog(false);
    } catch (error) {
      let message: string = '';
      let description: string = '';

      if (error === 409) {
        message = 'Benutzer existiert bereits';
        description =
          'Ein Benutzer mit dieser Email Adresse ist bereits vorhanden';
      } else {
        message = 'Unbekanter Fehler';
        description = 'Es ist ein unbekannter Fehler augetreten';
      }
      notification['error']({
        message,
        description,
      });

      throw error;
    }
  };

  const changePageSize = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setCurrentPageSize(pageSize);
  };

  const getAllUser = async (
    limit: number = 10,
    page: number = 1,
    q?: string
  ) => {
    try {
      const params: IParams = {
        limit: limit,
        skip: (page - 1) * limit,
      };

      if (q !== undefined) {
        params.q = q;
      }

      const response = await UserService.getMany(params);

      setUsers(response.data.result);
      setTotalEntries(response.data.totalPages * (currentPageSize ?? 10));
    } catch (error) {
      console.error(`Error finding users`, error);
    }
  };

  const openAddUserDialog = () => {
    setShowAddUserDialog(true);
  };

  const openEditUserDialog = (editUser: IUser) => {
    setEditUser(editUser);
    setShowAddUserDialog(true);
  };

  const handleDeleteUser = (user: IUser) => {
    Modal.confirm({
      title: 'Benutzer löschen',
      content: `Soll der Benutzer ${user.email} wirklich gelöscht werden?`,
      onOk: async () => {
        try {
          await UserService.delete(user._id!);
          notification.success({
            message: 'Benutzer gelöscht',
            description: `Der Benutzer ${user.email} wurde gelöscht`,
          });
          getAllUser(currentPageSize ?? 10, currentPage ?? 1);
        } catch (error) {
          notification.error({
            message: 'Fehler beim Löschen',
            description: `Der Benutzer ${user.email} konnte nicht gelöscht werde`,
          });
          console.log(error);
        }
      },
    });
  };

  return (
    <>
      <Row>
        <Col>
          <Title level={2}>Benutzerverwaltung</Title>
        </Col>
      </Row>
      <Row>
        <Col flex={'auto'}>
          <Space split={<Divider type="vertical" />}>
            <Tooltip placement="top" title={'Benutzer hinzufügen'}>
              <Button onClick={openAddUserDialog} icon={<UserAddOutlined />} />
            </Tooltip>
          </Space>
        </Col>
        <Col>
          <Input.Search
            allowClear
            placeholder="Suchen..."
            onSearch={(val) => getAllUser(currentPageSize ?? 10, 1, val)}
          />
        </Col>
      </Row>
      <Row style={{ paddingTop: 10 }}>
        <Col span={24}>
          {users && (
            <Table
              columns={UserTableColumns}
              dataSource={users}
              pagination={{
                current: currentPage ?? 1,
                pageSize: currentPageSize ?? 10,
                pageSizeOptions: [10, 20, 50, 100],
                onChange: changePageSize,
                total: totalEntries,
                showSizeChanger: true,
                position: ['topLeft'],
              }}
              onChange={(pagination, filter, sorter) => {}}
            />
          )}
        </Col>
      </Row>
      <AddUserModal
        isVisible={showAddUserDialog}
        onOk={saveNewUser}
        onCancel={() => {
          setShowAddUserDialog(false);
          setEditUser(undefined);
        }}
        userObject={editUser}
      />
    </>
  );
};

export default UsersPage;
