import {
  AuthenticationResponse,
  Service,
  IUser,
  IApiToken,
} from '@ppulwey/opttocut-library';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import qs from 'qs';
import { LocalStorageKeys } from './AppCommon';
import { history } from './utils/history';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  return config;
};
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error('[request error]', JSON.stringify(error, null, 2));
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  console.info('[response error]', JSON.stringify(error, null, 2));

  if (error.response?.status === 401) {
    localStorage.removeItem(LocalStorageKeys.accessToken);
    history.replace('/');
  }

  return Promise.reject(error);
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

const httpClient = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

setupInterceptorsTo(httpClient);

const authenticationService = {
  authenticate: async (data: any) => {
    return httpClient.post<AuthenticationResponse>('authenticate/login', data);
  },
  me: async (data: any) => {
    return httpClient.post('authenticate/me', data);
  },
  logOut: async (data: any) => {
    return httpClient.post('authenticate/logout', data);
  },
  deleteSession: async (data: { userId: string }) => {
    return httpClient.post('authenticate/delete-session', data);
  },
  resetPassword: async (data: { email: string }) => {
    return httpClient.post('authenticate/reset-password', data);
  },
};

const UserService = new Service<IUser>('user', httpClient);
const ApiTokenService = new Service<IApiToken>('apitoken', httpClient);

type CurveFitInit = {
  xData: number[];
  zData: number[];
};
const curvefitService = new Service<CurveFitInit | any>('optimize', httpClient);

export {
  httpClient,
  authenticationService,
  UserService,
  curvefitService,
  ApiTokenService,
};
