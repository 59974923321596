import {
  IParams,
  JsonPaginatedResponse,
  JsonResponse,
  Service,
} from '@ppulwey/opttocut-library';
import { useMemo, useRef, useState } from 'react';

export const useService = <T,>(service: Service<T>) => {
  const [singleData, setSingleData] = useState<JsonResponse<T> | null>(null);
  const [allData, setAllData] = useState<JsonPaginatedResponse<T> | null>(null);

  const [getManyLastUsedParams, setGetManyLastUsedParams] = useState<{
    params?: IParams;
    path?: string;
  }>();

  const getEntry = async (
    id?: string,
    params?: IParams,
    path?: string,
    rethrowError = false
  ) => {
    try {
      const result = await service.get(id, params, path);
      setSingleData(result.data);
    } catch (error) {
      console.error(`Error getting data`, error);
      setSingleData(null);

      if (rethrowError) {
        throw error;
      }
    }
  };

  const getManyEntries = async (
    params?: IParams,
    path?: string,
    rethrowError = false
  ) => {
    try {
      setGetManyLastUsedParams({
        params,
        path,
      });
      const result = await service.getMany(params, path);
      setAllData(result.data);
    } catch (error) {
      console.error(`Error getting data`, error);
      setAllData(null);

      if (rethrowError) {
        throw error;
      }
    }
  };

  const postEntry = async (
    data: Partial<T>,
    path?: string,
    rethrowError = false
  ) => {
    try {
      const result = await service.post(data, path);

      // * Get all data from Service again
      getManyEntries(
        getManyLastUsedParams?.params,
        getManyLastUsedParams?.path
      );
      return result.data;
    } catch (error) {
      console.error(`Error posting data`, error);

      if (rethrowError) {
        throw error;
      }
    }
  };

  const patchEntry = async (
    id: string,
    data: Partial<T>,
    path?: string,
    rethrowError = false
  ) => {
    try {
      const result = await service.patch(id, data, path);
      // * Get all data from Service again
      getManyEntries(
        getManyLastUsedParams?.params,
        getManyLastUsedParams?.path
      );
      return result.data;
    } catch (error) {
      console.error(`Error patching entry`, error);

      if (rethrowError) {
        throw error;
      }
    }
  };

  const deleteEntry = async (
    id: string,
    path?: string,
    rethrowError = false
  ) => {
    try {
      const result = await service.delete(id, path);
      // * Get all data from Service again
      getManyEntries(
        getManyLastUsedParams?.params,
        getManyLastUsedParams?.path
      );
      return result.data;
    } catch (error) {
      console.error(`Error deleting entry`, error);

      if (rethrowError) {
        throw error;
      }
    }
  };

  const functions = useRef<{
    getManyEntries: (
      params?: IParams,
      path?: string,
      rethrowError?: boolean
    ) => Promise<void>;
    getEntry: (
      id?: string,
      params?: IParams,
      path?: string,
      rethrowError?: boolean
    ) => Promise<void>;
    postEntry: (
      data: Partial<T>,
      path?: string,
      rethrowError?: boolean
    ) => Promise<JsonResponse<T> | undefined>;
    patchEntry: (
      id: string,
      data: Partial<T>,
      path?: string,
      rethrowError?: boolean
    ) => Promise<JsonResponse<T> | undefined>;
    deleteEntry: (
      id: string,
      path?: string,
      rethrowError?: boolean
    ) => Promise<JsonResponse<T> | undefined>;
  }>({ getEntry, getManyEntries, postEntry, patchEntry, deleteEntry }).current;

  return [{ singleData, allData }, functions] as const;
};
