import { createContext, PropsWithChildren, useEffect, useState } from 'react';

import {
  AuthenticationRequest,
  AuthenticationResponse,
  IUser,
} from '@ppulwey/opttocut-library';
import { LocalStorageKeys } from '../AppCommon';
import { authenticationService, httpClient } from '../client';
import { isJwtExpired } from '../utils/jwt';

function notImplemented() {
  throw new Error(`Function not implemented`);
}

type ComponentProps = {};

type ContextProps = {
  authCheckPending: boolean;
  isAuthenticated: boolean;
  user: Partial<IUser> | undefined;
  token: string | undefined;
  logIn: (
    param: AuthenticationRequest
  ) => Promise<AuthenticationResponse | undefined> | void;
  reAuthenticate: () => Promise<boolean> | void;
  logOut: () => Promise<any> | void;
};

const AuthenticationContext = createContext<ContextProps>({
  authCheckPending: false,
  isAuthenticated: false,
  token: undefined,
  user: undefined,
  logIn: notImplemented,
  logOut: notImplemented,
  reAuthenticate: notImplemented,
});

const AuthenticationProvider = ({
  children,
}: PropsWithChildren<ComponentProps>) => {
  const [authCheckPending, setAuthCheckPending] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<Partial<IUser> | undefined>(undefined);

  useEffect(() => {
    let intervalId: null | number = null;
    if (isAuthenticated) {
      intervalId = window.setInterval(sessionHeartbeat, 10000);
      return () => {
        if (intervalId !== null) {
          clearInterval(intervalId);
        }
      };
    }

    async function sessionHeartbeat() {
      try {
        await authenticationService.me({});
      } catch (error) {
        if (intervalId !== null) {
          clearInterval(intervalId);
        }
        console.error('Error session heartbeat', error);
      }
    }
  }, [isAuthenticated]);

  async function logIn(param: AuthenticationRequest) {
    try {
      const result = await authenticationService.authenticate(param);

      if (result.data.token === undefined) {
        throw new Error();
      }

      httpClient.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${result.data.token}`;

      // * Persist token in local storage
      localStorage.setItem(LocalStorageKeys.accessToken, result.data.token);

      setIsAuthenticated(true);
      setToken(result.data.token);
      setUser(result.data.user);

      return result.data;
    } catch (error) {
      console.error(`Error authenticating`, error);
      throw error;
    }
  }

  async function reAuthenticate() {
    const token = localStorage.getItem(LocalStorageKeys.accessToken);

    if (token === null || isJwtExpired(token)) {
      return false;
    }

    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
      const result = await authenticationService.me({});
      setToken(token);
      setIsAuthenticated(true);
      setUser(result.data.user);
      return true;
    } catch (error) {
      console.error('Error reauthenticating', error);
      return false;
    }
  }

  async function logOut() {
    try {
      await authenticationService.logOut({});
      localStorage.removeItem(LocalStorageKeys.accessToken);
      setIsAuthenticated(false);
      setToken(undefined);
      setUser(undefined);
    } catch (error) {
      console.error(`Error logging out`, error);
    }
  }

  return (
    <AuthenticationContext.Provider
      value={{
        authCheckPending: authCheckPending,
        isAuthenticated: isAuthenticated,
        token: token,
        user: user,
        logIn: logIn,
        reAuthenticate: reAuthenticate,
        logOut: logOut,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContext, AuthenticationProvider };
