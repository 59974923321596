import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  unstable_HistoryRouter as HistoryRouter,
} from 'react-router-dom';
import App from './App';
import './index.css';
import { AuthenticationProvider } from './Provider/AuthenticationProvider';
import store from './Redux/Store';
import reportWebVitals from './reportWebVitals';
import moment from 'moment';
import 'moment/locale/de';
import locale from 'antd/lib/locale/de_DE';
import { LocalStorageKeys, SettingsObject } from './AppCommon';
import { history } from './utils/history';

// * On App start check for existing settings object
const savedSettings = localStorage.getItem(LocalStorageKeys.settings);

if (savedSettings === null) {
  console.log('No settings object found. Apply default settings');
  alert('Die Standardeinstellungen werden geladen');
  const defaultSettings: SettingsObject = {
    lineColor: 'red',
    lineWidth: '0.5',
    markerColor: '#0000ff',
    pointMoveStep: '0.005',
    backgroundColor: '#ebebeb',
    axisColor: '#000000',
    markerSize: '2',
    selectedMarkerColor: '#ff0000',
    selectedMarkerSize: '3',
  };

  localStorage.setItem(
    LocalStorageKeys.settings,
    JSON.stringify(defaultSettings)
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <AuthenticationProvider>
      <HistoryRouter history={history}>
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </HistoryRouter>
    </AuthenticationProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
