import {
  CheckSquareOutlined,
  DotChartOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DownloadOutlined,
  FundOutlined,
  MinusCircleOutlined,
  MinusSquareFilled,
  PlusCircleOutlined,
  PlusSquareFilled,
  SettingOutlined,
  ShareAltOutlined,
  StockOutlined,
  StopOutlined,
  UndoOutlined,
  UploadOutlined,
  RadiusBottomleftOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Divider,
  InputNumber,
  notification,
  Row,
  Slider,
  Space,
  Spin,
  Tabs,
  Tooltip,
  Upload,
} from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import {
  Annotations,
  PlotDatum,
  PlotMouseEvent,
  PlotRelayoutEvent,
} from 'plotly.js';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Plot from 'react-plotly.js';
import { v4 as uuid } from 'uuid';
import { LocalStorageKeys, SettingsObject } from '../../AppCommon';
import { curvefitService } from '../../client';
import { AppPlotLayout, AppPlotPoint } from '../../Models/AppPlot';
import { SmoothModel } from '../../Models/Smooth.model';
import { SmoothIntervalModel } from '../../Models/SmoothInterval.model';
import { AuthenticationContext } from '../../Provider/AuthenticationProvider';
import { getCopy } from '../../utils/getCopy';
import { ExportContourModal } from './Components/ExportContourModal';
import { SettingsDrawer } from './Components/SettingsDrawer';
import './PlotterView.scss';

type ZCorrection = {
  executed: boolean;
  value: number | null;
};

type AppHistory = {
  plotPoints: AppPlotPoint[];
  extendContourRightExecuted: boolean;
  zCorrectionExecuted: ZCorrection;
};

let fileReader: FileReader;

const getArrayOrdered = (unsortedArray: number[] | undefined) => {
  if (unsortedArray === undefined) {
    return [];
  }

  const arrayCopy = unsortedArray.slice();
  arrayCopy.sort((a, b) => a - b);
  return arrayCopy;
};

function PlotterView() {
  const authContext = useContext(AuthenticationContext);
  const [zCorrectionExecuted, setZCorrectionExecuted] = useState<ZCorrection>({
    executed: false,
    value: null,
  });
  const [extendContourRightExecuted, setExtendContourRightExecuted] =
    useState(false);

  const [originalPlots, setOriginalPlots] = useState<AppPlotPoint[]>([]);
  const [headerRows, setHeaderRows] = useState<string[]>([]);
  const [plots, setPlots] = useState<AppPlotPoint[]>([]);
  const [smoothPlots, setSmoothPlots] = useState<AppPlotPoint[]>([]);
  const [footerRows, setFooterRows] = useState<string[]>([]);
  const [appHistory, _setAppHistory] = useState<AppHistory[]>([]);
  const [selectedPoints, setSelectedPoints] = useState<number[] | undefined>(
    undefined
  );
  const [hoverPoint, setHoverpoint] = useState<PlotDatum | undefined>(
    undefined
  );
  const [mousePoint, setMousePoint] = useState<{ x: number; z: number }>({
    x: 0,
    z: 0,
  });

  const [plotAnnotations, setPlotAnnotaions] =
    useState<Partial<Annotations>[]>();

  const [appSettings, setAppSettings] = useState<SettingsObject | undefined>();
  const [showOriginalPlot, setShowOriginalPlot] = useState<boolean>(false);
  const [showLines, setShowLines] = useState<boolean>(false);
  const [movePointSessionValue, setMovePointSessionValue] =
    useState<string>('0.005');

  const [modalExportContourVisisble, setModalExportContourVisisble] =
    useState<boolean>(false);
  const [modalExportContourRender, setModalExportContourRender] =
    useState<boolean>(false);

  const [drawerSettingsVisible, setDrawerSettingsVisible] =
    useState<boolean>(false);
  const [originalFilename, setOriginalFilename] = useState<string>('');

  const [modeZCorrection, setModeZCorrection] = useState<boolean>(false);
  const [zCorrectionVal, setZCorrectionVal] = useState<number>(-1);

  const [plotLayout, setPlotLayout] = useState<AppPlotLayout>();
  const [sessionId, setSessionId] = useState<string>();
  const [smoothValue, setSmoothValue] = useState<number>(0.08);
  const [smoothIntervalValue, setSmoothIntervalValue] = useState<number>(0.08);
  const [smoothIntervalWeight, setSmoothIntervalWeight] = useState<number>(1);

  const [smoothWorking, setSmoothWorking] = useState<boolean>(false);
  const [smoothApplied, setSmoothApplied] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const plotRef = useRef<Plot>(null);
  const plotsRef = useRef<AppPlotPoint[]>(plots);
  const appHistoryRef = useRef<AppHistory[]>([]);
  const selectedPointsRef = useRef<number[] | undefined>();
  const appSettingsRef = useRef<SettingsObject>();
  const movePointsSessionValueRef = useRef<string>();
  const zCorrectionValRef = useRef<number>();

  const setAppHistory = (val: AppHistory[]) => {
    appHistoryRef.current = val;
    _setAppHistory(val);
  };

  const getAppHistoryEntry = (currentPlots: AppPlotPoint[]) => {
    const plotsCopy = getCopy<AppPlotPoint[]>(currentPlots);
    const zCorrectionExecutedCopy = getCopy<ZCorrection>(zCorrectionExecuted);
    const extendContourRightExecutedCopy = getCopy<boolean>(
      extendContourRightExecuted
    );

    const historyEntry: AppHistory = {
      extendContourRightExecuted: extendContourRightExecutedCopy,
      plotPoints: plotsCopy,
      zCorrectionExecuted: zCorrectionExecutedCopy,
    };

    return historyEntry;
  };

  const selectedPointsCopyOrdered = useMemo(
    () => getArrayOrdered(selectedPoints),
    [selectedPoints]
  );

  const plotterMouseMove = (
    evt: Event,
    xaxis: any,
    yaxis: any,
    l: any,
    b: any
  ) => {
    //@ts-ignore
    const xInDataCoord = xaxis.p2c(evt.x - 80 - 8 - l);
    //@ts-ignore
    const yInDataCoord = yaxis.p2c(evt.y - 64 - 32 - 94 - 49 - b);

    setMousePoint({
      x: isNaN(xInDataCoord) ? 0 : xInDataCoord,
      z: isNaN(yInDataCoord) ? 0 : yInDataCoord,
    });
  };

  // ! Effects
  const loadSettings = useCallback(() => {
    const settingsVal = localStorage.getItem(LocalStorageKeys.settings);
    if (settingsVal === null) {
      return;
    }

    const settings = JSON.parse(settingsVal) as SettingsObject;
    const pointMoveStepValue = settings.pointMoveStep ?? '0.005';

    setAppSettings({
      ...appSettings,
      ...settings,
      pointMoveStep: pointMoveStepValue,
    });
    setMovePointSessionValue(pointMoveStepValue);
  }, [appSettings]);

  useEffect(() => {
    loadSettings();
  }, []);

  useEffect(() => {
    plotsRef.current = plots;
  }, [plots]);

  useEffect(() => {
    // * If in z-correction mode

    if (modeZCorrection) {
      setPlotAnnotaions((val) => {
        if (selectedPoints === undefined) {
          return val;
        }
        const newAnnotations: Partial<Annotations>[] = [];
        selectedPoints.forEach((currPoint, i) => {
          const newAnnotation: Partial<Annotations> = {
            x: plots[currPoint].x,
            y: plots[currPoint].z,
            text: i === 0 ? 'Start' : 'Ende',
            visible: true,
          };

          newAnnotations.push(newAnnotation);
        });
        return newAnnotations;
      });
    }
    selectedPointsRef.current = selectedPoints;
  }, [selectedPoints]);

  useEffect(() => {
    appSettingsRef.current = appSettings;
  }, [appSettings]);

  useEffect(() => {
    movePointsSessionValueRef.current = movePointSessionValue;
  }, [movePointSessionValue]);

  useEffect(() => {
    if (modeZCorrection) {
      notification.open({
        key: 'mode-zcorrection',
        message: 'Modus Z-Korrektur',
        description: 'Bitte genau zwei Punkte wählen',
        duration: null,
        closeIcon: (
          <Button
            type="text"
            size="small"
            onClick={() => setModeZCorrection(false)}
          >
            Abbrechen
          </Button>
        ),
        btn: (
          <Button
            type="primary"
            size="small"
            onClick={() => handleModalZCorrectionOk()}
          >
            OK
          </Button>
        ),
      });
    } else {
      notification.close('mode-zcorrection');
      setSelectedPoints(undefined);
      setPlotAnnotaions(undefined);
    }
  }, [modeZCorrection]);

  useEffect(() => {
    zCorrectionValRef.current = zCorrectionVal;
  }, [zCorrectionVal]);

  useEffect(() => {
    window.addEventListener('keydown', handleArrowKeys);
    // if (modalExportContourVisisble) {
    //   window.removeEventListener('keydown', handleArrowKeys);
    // } else {
    // }

    return () => {
      window.removeEventListener('keydown', handleArrowKeys);
    };
  }, [modalExportContourVisisble]);

  const selectionSmooth = async (smooth: number, weight: number) => {
    try {
      setSmoothWorking(true);

      console.log(selectedPointsCopyOrdered);

      const firstSelectedPointIndex = selectedPointsCopyOrdered[0];
      const lastSelectedPointIndex =
        selectedPointsCopyOrdered[selectedPointsCopyOrdered.length - 1];

      const xStart = plotsRef.current[firstSelectedPointIndex].x;
      const xEnd = plotsRef.current[lastSelectedPointIndex].x;

      const body: SmoothIntervalModel = {
        smooth,
        weight,
        TOKEN: sessionId,
        interval: [xEnd, xStart],
        ticksDisplay: 5000,
      };

      const response = await curvefitService.post(body, 'smoothinterval');
      const smoothPlotResult: AppPlotPoint[] = [];

      for (let index = 0; index < response.data.result.xData.length; index++) {
        smoothPlotResult.push({
          x: response.data.result.xData[index],
          z: response.data.result.zData[index],
        });
      }

      setSmoothPlots(smoothPlotResult);
    } catch (error) {
      console.error(error);
    } finally {
      setSmoothWorking(false);
    }
  };

  const initSmooth = async () => {
    try {
      setSmoothWorking(true);
      const xData = plotsRef.current.map((x) => x.x);
      const zData = plotsRef.current.map((x) => x.z);

      const body: SmoothModel = {
        tolerance: smoothValue,
        xData,
        zData,
        TOKEN: sessionId,
      };

      const response = await curvefitService.post(body, 'init');
      const smoothPlotResult: AppPlotPoint[] = [];

      for (let index = 0; index < response.data.result.xData.length; index++) {
        smoothPlotResult.push({
          x: response.data.result.xData[index],
          z: response.data.result.zData[index],
        });
      }

      setSmoothPlots(smoothPlotResult);
    } catch (error) {
      console.error(error);
    } finally {
      setSmoothWorking(false);
    }
  };

  const handleSmoothIntervalValueChange = (val: number) => {
    setSmoothIntervalValue((_) => val);
    selectionSmooth(val, smoothIntervalWeight);
  };

  const handleSmoothIntervalWeightValueChange = (val: string) => {
    const valNumber = Number.parseFloat(val);

    if (isNaN(valNumber)) {
      console.error(`Cannot parse smoothIntervalWeightNumber`);
      return;
    }

    setSmoothIntervalWeight((_) => valNumber);
    selectionSmooth(smoothIntervalValue, valNumber);
  };

  const handleSmoothValueChange = async (val: number) => {
    try {
      setSmoothWorking(true);

      // * Check right val condition
      let cleanSmoothValue = 0;
      if (val < 0) {
        notification.open({
          message: 'Glättungswert min. 0',
          description: 'Der Glättungswert kann nicht kleiner 0 sein',
          type: 'warning',
          duration: 5,
        });
        cleanSmoothValue = 0;
      } else if (val > 10) {
        notification.open({
          message: 'Glättungswert max. 10',
          description: 'Der Glättungswert kann nicht größer 10 sein',
          type: 'warning',
          duration: 5,
        });
        cleanSmoothValue = 10;
      } else {
        cleanSmoothValue = val;
      }

      setSmoothValue(cleanSmoothValue);
      const body: SmoothModel = {
        smooth: cleanSmoothValue,
        TOKEN: sessionId,
      };

      const response = await curvefitService.post(body, 'smooth');

      const smoothPlotResult: AppPlotPoint[] = [];

      for (let index = 0; index < response.data.result.xData.length; index++) {
        smoothPlotResult.push({
          x: response.data.result.xData[index],
          z: response.data.result.zData[index],
        });
      }

      setSmoothPlots(smoothPlotResult);
    } catch (error) {
      console.error('Error handling smooth', error);
    } finally {
      setSmoothWorking(false);
    }
  };

  const handleApplySmooth = () => {
    const historyEntry = getAppHistoryEntry(plots);
    setAppHistory(appHistoryRef.current.concat([historyEntry]));

    setPlots(smoothPlots);
    setSmoothPlots([]);
    setSmoothApplied(true);
  };

  const resetSmoothPlots = () => {
    setSmoothValue(0.08);
    setSmoothIntervalValue(0.08);
    setSmoothIntervalWeight(1);
    setSmoothPlots([]);
  };

  const handleToggleModeZCorrection = () => {
    setSelectedPoints(undefined);
    setModeZCorrection((val) => !val);
  };

  const handleModalZCorrectionOk = () => {
    let direction: 'ltr' | 'rtl' = 'rtl';

    if (
      zCorrectionValRef.current === undefined ||
      selectedPointsRef.current === undefined ||
      selectedPointsRef.current.length < 2
    ) {
      return;
    }

    setZCorrectionExecuted({
      executed: true,
      value: zCorrectionValRef.current,
    });

    const plotsCopy = getCopy<AppPlotPoint[]>(plots);

    // * Get Start and end point
    let startIndex = selectedPointsRef.current[0];
    const endIndex = selectedPointsRef.current[1];

    if (startIndex > endIndex) {
      direction = 'ltr';
    } else {
      startIndex += 1;
    }

    // * Get all points in range start > end
    const workingPoints =
      direction === 'rtl'
        ? plotsCopy.slice(startIndex, endIndex + 1)
        : plotsCopy.slice(endIndex, startIndex);
    const splitCorrectionValue =
      zCorrectionValRef.current / workingPoints.length;

    let counter = direction === 'rtl' ? 0 : workingPoints.length - 1;

    for (const currPoint of workingPoints) {
      currPoint.z += splitCorrectionValue * (counter + 1);
      if (direction === 'rtl') {
        counter++;
      } else {
        counter--;
      }
    }

    const dataCopy = [...plotsCopy];

    if (direction === 'rtl') {
      dataCopy.splice(startIndex, endIndex - startIndex + 1, ...workingPoints);
    } else {
      dataCopy.splice(endIndex, startIndex - endIndex, ...workingPoints);
    }

    setPlots(dataCopy);
    const historyEntry = getAppHistoryEntry(dataCopy);
    setAppHistory(appHistoryRef.current.concat([historyEntry]));
    setSelectedPoints(undefined);
    setModeZCorrection(false);
  };

  const handleExtendContourLeft = () => {
    const plotsCopy = getCopy<AppPlotPoint[]>(plots);

    // * Get Start and end point to calculate values
    const calcPointStartIndex = selectedPointsCopyOrdered[0];
    const calcPointEndIndex =
      selectedPointsCopyOrdered[selectedPointsCopyOrdered.length - 1];
    const firstZCalcPoint = plotsCopy[calcPointStartIndex];
    const lastZCalcPoint = plotsCopy[calcPointEndIndex];
    const normalXInterval = firstZCalcPoint.x - lastZCalcPoint.x;
    const ascent = lastZCalcPoint.z - firstZCalcPoint.z;

    // * Working points to manipulate from selected end index + 1 (we don't move the first point) to end of contour
    const pointsToChange = plotsCopy.slice(calcPointEndIndex + 1, plots.length);

    const referenceZVal = lastZCalcPoint.z;

    pointsToChange.forEach((currPoint, i) => {
      if (i === pointsToChange.length - 1) {
        return;
      }
      const newZVal = referenceZVal + (i + 1) * ascent;
      currPoint.z = newZVal;
    });

    // * Special handling for last point
    const lastPoint = pointsToChange[pointsToChange.length - 1];
    const secondLastPoint = pointsToChange[pointsToChange.length - 2];
    const lastXInterval = secondLastPoint.x - lastPoint.x;

    // ? This is the value that will be added to the Z value of the second last point, resulting in the Z value for the last point,
    const lastZCalcVal = (ascent * lastXInterval) / normalXInterval;

    lastPoint.z = secondLastPoint.z + lastZCalcVal;

    plotsCopy.splice(calcPointEndIndex + 1, plots.length, ...pointsToChange);

    setPlots(plotsCopy);
    const historyEntry = getAppHistoryEntry(plotsCopy);
    setAppHistory(appHistoryRef.current.concat([historyEntry]));
    setSelectedPoints(undefined);
  };

  const handleExtendContourRight = () => {
    const plotsCopy = getCopy<AppPlotPoint[]>(plots);
    // * Get Start and end point to calculate values
    const calcPointStartIndex = selectedPointsCopyOrdered[0];
    const calcPointEndIndex =
      selectedPointsCopyOrdered[selectedPointsCopyOrdered.length - 1];
    const firstZCalcPoint = plotsCopy[calcPointStartIndex];
    const lastZCalcPoint = plotsCopy[calcPointEndIndex];
    const ascent = firstZCalcPoint.z - lastZCalcPoint.z;

    // * Working points to manipulate from selected end index + 1 (we don't move the first point) to end of contour
    let pointToChange = plotsCopy.slice(0, calcPointStartIndex);

    const referenceZVal = firstZCalcPoint.z;

    pointToChange = pointToChange.reverse();
    pointToChange.forEach((currPoint, i) => {
      const newZVal = referenceZVal + (i + 1) * ascent;
      currPoint.z = newZVal;
    });

    pointToChange = pointToChange.reverse();
    plotsCopy.splice(0, calcPointStartIndex, ...pointToChange);

    setPlots(plotsCopy);
    const historyEntry = getAppHistoryEntry(plotsCopy);
    setAppHistory(appHistoryRef.current.concat([historyEntry]));
    setSelectedPoints(undefined);
    setExtendContourRightExecuted(true);
  };

  const handleDrawerSettingsSave = () => {
    loadSettings();
    setDrawerSettingsVisible(false);
  };

  const handleDrawerSettingsCancel = () => {
    setDrawerSettingsVisible(false);
  };

  // ! Plot Edit
  const handleUndoLastStep = () => {
    if (appHistoryRef.current.length === 1) {
      return;
    }

    let appHistoryCopy = appHistoryRef.current.slice();

    let prevHistories: AppHistory[] = [];
    let prevHistory: AppHistory;

    if (appHistoryCopy.length === 2) {
      prevHistories = [appHistoryCopy[0]];
      prevHistory = appHistoryCopy[0];
    } else {
      prevHistories = appHistoryCopy.slice(0, appHistoryCopy.length - 1);
      prevHistory = prevHistories[prevHistories.length - 1];
    }

    setPlots(prevHistory.plotPoints);
    setZCorrectionExecuted(prevHistory.zCorrectionExecuted);
    setExtendContourRightExecuted(prevHistory.extendContourRightExecuted);
    setAppHistory(prevHistories);
  };

  const handleDeltePoints = () => {
    const selectedPointsCopy = [...selectedPointsCopyOrdered];

    const plotsCopy = plots.slice();

    // * Delete indeces from plots
    for (const currIndex of selectedPointsCopy) {
      plotsCopy.splice(currIndex, 1, { x: 0, z: 0, markDelete: true });
    }

    const newPlots = plotsCopy.filter(
      (x) => x.markDelete === undefined || x.markDelete === false
    );

    setPlots(newPlots);
    const historyEntry = getAppHistoryEntry(newPlots);
    setAppHistory(appHistoryRef.current.concat([historyEntry]));
    setSelectedPoints(undefined);
  };

  const handleAddPoint = () => {
    const selectedPointsCopy = [...selectedPointsCopyOrdered];
    const plotsCopy = plots.slice();

    if (selectedPointsCopy.length !== 2) {
      setSelectedPoints(undefined);
      return;
    }

    // * Get selected points
    const firstPoint = plotsCopy[selectedPointsCopy[0]];
    const secondPoint = plotsCopy[selectedPointsCopy[1]];

    // * Get the center coordinated between points
    const centerPoint: AppPlotPoint = {
      x: firstPoint.x + (secondPoint.x - firstPoint.x) / 2,
      z: firstPoint.z + (secondPoint.z - firstPoint.z) / 2,
    };

    plotsCopy.splice(selectedPointsCopy[0] + 1, 0, centerPoint);
    setSelectedPoints([selectedPointsCopy[0] + 1]);
    setPlots(plotsCopy);
    const historyEntry = getAppHistoryEntry(plotsCopy);
    setAppHistory(appHistoryRef.current.concat([historyEntry]));
  };

  const handleUnselectAll = () => {
    setSelectedPoints(undefined);
  };

  const handleArrowKeys = (e: KeyboardEvent) => {
    const { key, altKey } = e;
    const handleKeys = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'];

    if (!handleKeys.includes(key) || (handleKeys.includes(key) && !altKey)) {
      return;
    }
    e.preventDefault();
    console.log('Pressed', key, altKey);

    let newSelectedPoints: number[] = [];

    const moveValue = Number.parseFloat(
      movePointsSessionValueRef.current ?? '0.005'
    );
    let moveHorizontalSelection: 'left' | 'right' | undefined = undefined;
    const pointsSelected =
      selectedPointsRef.current !== undefined &&
      selectedPointsRef.current.length > 0;
    const isLeftEnd =
      selectedPointsRef.current !== undefined &&
      selectedPointsRef.current[0] === plotsRef.current.length - 1;
    const isRightEnd =
      selectedPointsRef.current === undefined ||
      selectedPointsRef.current[0] === 0;

    switch (key) {
      case 'ArrowRight': {
        moveHorizontalSelection = 'right';
        break;
      }
      case 'ArrowLeft': {
        moveHorizontalSelection = 'left';
        break;
      }
      case 'ArrowUp': {
        moveSelectedPoints(moveValue);
        break;
      }
      case 'ArrowDown': {
        moveSelectedPoints(moveValue * -1);
        break;
      }

      default:
        break;
    }

    if (!pointsSelected) {
      //* No point is currently selected
      if (moveHorizontalSelection === 'left') {
        newSelectedPoints = [0];
      } else if (moveHorizontalSelection === 'right') {
        newSelectedPoints = [plotsRef.current.length - 1];
      }
    } else {
      //* There are selected points
      if (moveHorizontalSelection === 'left') {
        if (isLeftEnd) {
          newSelectedPoints = [0];
        } else {
          newSelectedPoints = [selectedPointsRef.current![0] + 1];
        }
      } else if (moveHorizontalSelection === 'right') {
        if (isRightEnd) {
          newSelectedPoints = [plotsRef.current.length - 1];
        } else {
          newSelectedPoints = [selectedPointsRef.current![0] - 1];
        }
      }
    }

    setSelectedPoints((val) =>
      newSelectedPoints.length === 0 ? val : newSelectedPoints
    );
  };

  const moveSelectedPoints = (val: number) => {
    if (selectedPointsRef.current === undefined) {
      return;
    }

    const plotsCopy = JSON.parse(JSON.stringify(plotsRef.current));

    selectedPointsRef.current.forEach((currPoint) => {
      plotsCopy[currPoint].z += val;
    });

    setPlots(plotsCopy);
    const historyEntry = getAppHistoryEntry(plotsCopy);
    setAppHistory(appHistoryRef.current.concat([historyEntry]));
  };

  const handleSetPointSelection = (e: PlotMouseEvent) => {
    const { points, event } = e;
    const { pointIndex, curveNumber } = points[0];

    // * Curve number 1 is always the original curve -> Cannot be selected
    // * If z-correction mode only 2 points can be selected
    if (
      curveNumber === 1 ||
      (modeZCorrection &&
        selectedPointsRef.current &&
        selectedPointsRef.current.length === 2)
    ) {
      return;
    }

    let selectedpointsCopy: number[] = [];
    if (selectedPoints !== undefined) {
      selectedpointsCopy = [...selectedPoints];
    }

    const foundIndex = selectedpointsCopy.findIndex((x) => x === pointIndex);

    if (foundIndex > -1) {
      selectedpointsCopy.splice(foundIndex, 1);
    } else {
      selectedpointsCopy.push(pointIndex);
    }

    setSelectedPoints(
      selectedpointsCopy.length === 0 ? undefined : selectedpointsCopy
    );
  };

  const handleHover = (point: PlotMouseEvent) => {
    if (point.points.length <= 0) {
      return;
    }
    const hoverPoint = point.points[0];
    setHoverpoint(hoverPoint);
  };

  const handleFileExported = async (filename: string, fileRows: string) => {
    const opt = {
      suggestedName: filename,
    };
    //@ts-ignore
    const handle = await window.showSaveFilePicker(opt);

    const writableStream = await handle.createWritable();
    await writableStream.write(fileRows);
    await writableStream.close();

    setModalExportContourVisisble(false);
  };

  const handleFileRead = () => {
    const content = fileReader.result as string;
    if (content === undefined || content === null || content.length === 0) {
      return;
    }

    const contentArray = content.split('\n');
    const cleanContentArray = contentArray.map((c) =>
      c.replace(/[\u0000-\u001F\u007F-\u009F\u200B\;]/g, '')
    );

    const header: string[] = [];
    const plotterRows: string[] = [];
    const footer: string[] = [];

    let isStart = true;
    cleanContentArray.forEach((val, i) => {
      if (val.length === 0 || val === '') {
        return;
      }
      if (val.toLocaleLowerCase().startsWith('g01x')) {
        isStart = false;
        plotterRows.push(val);
      } else {
        if (isStart) {
          header.push(val);
        } else {
          footer.push(val);
        }
      }
    });

    // const plotterRows = cleanContentArray.filter((x) =>
    //   x.toLowerCase().startsWith("g01x")
    // );

    const plots = plotterRows.map((x, i) => {
      const row = x.toLowerCase();
      const splitRow = row.replace('g01x', '').split('z');

      return {
        x: Number.parseFloat(splitRow[0]),
        z: Number.parseFloat(splitRow[1]),
      };
    });

    console.log(`Setting ${plots.length} plot points`);

    setHeaderRows(header);
    setFooterRows(footer);
    setPlots(plots);
    setOriginalPlots(plots);
    setAppHistory([
      {
        plotPoints: plots,
        extendContourRightExecuted: false,
        zCorrectionExecuted: {
          executed: false,
          value: null,
        },
      },
    ]);
    setSelectedPoints(undefined);
    setSmoothApplied(false);
    setZCorrectionExecuted({ executed: false, value: null });
    setExtendContourRightExecuted(false);
    resetSmoothPlots();
  };

  const handleFileSelected = (event: UploadChangeParam) => {
    setSmoothPlots([]);
    setSessionId(uuid());
    setSmoothValue(0.08);
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(event.fileList[0].originFileObj!);
    setOriginalFilename(event.fileList[0].name);
  };

  const handlePlotRelayout = (e: Readonly<PlotRelayoutEvent>) => {
    const plot = document.querySelector('.js-plotly-plot');
    if (plot) {
      //@ts-ignore
      var xaxis = plot._fullLayout.xaxis;
      //@ts-ignore
      var yaxis = plot._fullLayout.yaxis;
      //@ts-ignore
      var marginLeft = plot._fullLayout.margin.l;
      //@ts-ignore
      var marginBottom = plot._fullLayout.margin.b;
      plot.removeEventListener('mousemove', (evt) =>
        plotterMouseMove(evt, xaxis, yaxis, marginLeft, marginBottom)
      );
      plot.addEventListener('mousemove', (evt) =>
        plotterMouseMove(evt, xaxis, yaxis, marginLeft, marginBottom)
      );
    }

    const newLayout: AppPlotLayout = {
      xaxis: {
        range: [e['xaxis.range[0]']!, e['xaxis.range[1]']!],
      },
      yaxis: {
        range: [e['yaxis.range[0]']!, e['yaxis.range[1]']!],
      },
    };

    setPlotLayout(newLayout);
  };

  const handleShowExportDialog = () => {
    if (smoothPlots.length > 0 && smoothApplied === false) {
      notification.open({
        message: 'Achtung!',
        description: 'Die Glättung wurde noch nicht angewendet',
        type: 'warning',
        duration: null,
      });
    }
    setModalExportContourVisisble(true);
    setModalExportContourRender(true);
  };

  if (appSettings === undefined) {
    return (
      <div className="container__loading">
        <Spin tip="Einstellungen werden geladen" />
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col flex="auto">
          <Space split={<Divider type="vertical" />}>
            <Upload
              onChange={handleFileSelected}
              beforeUpload={() => false}
              showUploadList={false}
              multiple={false}
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>Kontur öffnen</Button>
            </Upload>

            <Tooltip placement="top" title={'Schritt Rückgängig machen'}>
              <Badge
                count={appHistory.length > 1 ? appHistory.length - 1 : 0}
                overflowCount={99}
                size="small"
              >
                <Button
                  onClick={handleUndoLastStep}
                  icon={<UndoOutlined />}
                  disabled={appHistory.length <= 1}
                />
              </Badge>
            </Tooltip>
            <Tooltip placement="top" title={'Auswahl aufheben'}>
              <Button
                onClick={handleUnselectAll}
                icon={<StopOutlined />}
                disabled={
                  selectedPoints === undefined || selectedPoints?.length === 0
                }
              />
            </Tooltip>
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                handleShowExportDialog();
              }}
            >
              Kontur Exportieren
            </Button>
            <span>
              {mousePoint &&
                `x: ${Number.parseFloat(
                  mousePoint.x ? mousePoint.x.toString() : ''
                ).toFixed(4)} z: ${Number.parseFloat(
                  mousePoint.z ? mousePoint.z.toString() : ''
                ).toFixed(4)}`}
            </span>

            <span>
              {hoverPoint &&
                `x: ${Number.parseFloat(
                  hoverPoint.x ? hoverPoint.x.toString() : ''
                ).toFixed(4)} z: ${Number.parseFloat(
                  hoverPoint.y ? hoverPoint.y.toString() : ''
                ).toFixed(4)}`}
            </span>
            <span>
              {originalFilename.length === 0
                ? '- Keine Kontur -'
                : originalFilename}
            </span>
          </Space>
        </Col>
        <Col>
          <Space>
            <Tooltip placement="top" title={'Linen ein-/ausblenden'}>
              <Button
                onClick={() => setShowLines((val) => !val)}
                icon={<DotChartOutlined />}
                type={showLines ? 'primary' : 'default'}
              />
            </Tooltip>
            <Tooltip placement="top" title={'Original anzeigen'}>
              <Button
                onClick={() => setShowOriginalPlot((val) => !val)}
                icon={<FundOutlined />}
                type={showOriginalPlot ? 'primary' : 'default'}
              />
            </Tooltip>
            <Tooltip placement="top" title={'Wert Punkte verschieben'}>
              <InputNumber
                keyboard={false}
                min={'0'}
                max={'10'}
                defaultValue={appSettings.pointMoveStep}
                value={movePointSessionValue}
                step="0.001"
                stringMode
                onChange={(val) => setMovePointSessionValue(val.toString())}
              />
            </Tooltip>
            <Button
              onClick={() => setDrawerSettingsVisible(true)}
              icon={<SettingOutlined />}
            />
          </Space>
        </Col>
      </Row>
      <Row>
        <Tabs>
          <Tabs.TabPane tab="Korrektur" key={'tab_correction'}>
            <Row>
              <Col flex={'auto'}>
                <Space>
                  <Tooltip placement="top" title={'Z-Korrektur'}>
                    <Button
                      onClick={handleToggleModeZCorrection}
                      icon={<StockOutlined />}
                    />
                  </Tooltip>
                  {modeZCorrection && (
                    <InputNumber
                      defaultValue={zCorrectionVal}
                      onChange={(val) =>
                        setZCorrectionVal(Number.parseFloat(val.toString()))
                      }
                      step="0.01"
                      stringMode
                    />
                  )}
                  <Tooltip placement="top" title={'Verlängerung Radmitte'}>
                    <Button
                      onClick={handleExtendContourLeft}
                      icon={<DoubleLeftOutlined />}
                    />
                  </Tooltip>
                  <Tooltip placement="top" title={'Verlängerung Felgenhorn'}>
                    <Button
                      onClick={handleExtendContourRight}
                      icon={<DoubleRightOutlined />}
                    />
                  </Tooltip>
                  <Tooltip placement="top" title={'Ausgewählte Punkte löschen'}>
                    <Button
                      onClick={handleDeltePoints}
                      icon={<MinusCircleOutlined />}
                    />
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    title={
                      'Punkt hinzufügen (zwischen zwei ausgewählten Punkten)'
                    }
                  >
                    <Button
                      onClick={handleAddPoint}
                      icon={<PlusCircleOutlined />}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Gesamtoptimierung" key={'tab_optimize'}>
            <Space>
              <Tooltip placement="top" title={'Initiale Glättung'}>
                <Button
                  onClick={initSmooth}
                  icon={<ShareAltOutlined />}
                  loading={smoothWorking}
                />
              </Tooltip>
              <Space>
                <Button
                  loading={smoothWorking}
                  icon={<MinusSquareFilled />}
                  type="text"
                  onClick={() => handleSmoothValueChange(smoothValue - 0.01)}
                  disabled={smoothPlots.length === 0}
                />
                <Slider
                  disabled={smoothWorking || smoothPlots.length === 0}
                  min={0}
                  max={10}
                  step={0.01}
                  style={{ width: 300 }}
                  //tooltipVisible={true}
                  tooltipPlacement="bottom"
                  onAfterChange={handleSmoothValueChange}
                  value={smoothValue}
                  onChange={(val) => setSmoothValue(val)}
                />
                <Button
                  loading={smoothWorking}
                  icon={<PlusSquareFilled />}
                  type="text"
                  onClick={() => handleSmoothValueChange(smoothValue + 0.01)}
                  disabled={smoothPlots.length === 0}
                />
              </Space>
              <Tooltip placement="top" title={'Glättung löschen'}>
                <Button
                  loading={smoothWorking}
                  onClick={resetSmoothPlots}
                  icon={<StopOutlined />}
                  disabled={smoothPlots.length === 0}
                />
              </Tooltip>
              <Tooltip placement="top" title={'Glättung anwenden'}>
                <Button
                  loading={smoothWorking}
                  onClick={handleApplySmooth}
                  icon={<CheckSquareOutlined />}
                  disabled={smoothPlots.length === 0}
                />
              </Tooltip>
            </Space>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Teiloptimierung" key={'tab_partoptimize'}>
            <Space>
              <span>Glättungsfaktor:</span>
              <Space>
                <Button
                  loading={smoothWorking}
                  icon={<MinusSquareFilled />}
                  type="text"
                  onClick={() =>
                    handleSmoothIntervalValueChange(smoothIntervalValue - 0.01)
                  }
                  disabled={
                    smoothWorking ||
                    // smoothPlots.length === 0 ||
                    selectedPointsRef.current === undefined ||
                    selectedPointsRef.current.length === 0
                  }
                />
                <Slider
                  min={0}
                  max={10}
                  step={0.01}
                  style={{ width: 300 }}
                  //tooltipVisible={true}
                  tooltipPlacement="bottom"
                  onAfterChange={handleSmoothIntervalValueChange}
                  value={smoothIntervalValue}
                  onChange={(val) => setSmoothIntervalValue(val)}
                  disabled={
                    smoothWorking ||
                    // smoothPlots.length === 0 ||
                    selectedPointsRef.current === undefined ||
                    selectedPointsRef.current.length === 0
                  }
                />
                <Button
                  loading={smoothWorking}
                  icon={<PlusSquareFilled />}
                  type="text"
                  onClick={() =>
                    handleSmoothIntervalValueChange(smoothIntervalValue + 0.01)
                  }
                  disabled={
                    smoothWorking ||
                    // smoothPlots.length === 0 ||
                    selectedPointsRef.current === undefined ||
                    selectedPointsRef.current.length === 0
                  }
                />
              </Space>
              <span>Gewichtung:</span>
              <InputNumber<string>
                min="1"
                max="15"
                step="1"
                value={smoothIntervalWeight.toString()}
                onChange={handleSmoothIntervalWeightValueChange}
                stringMode
                disabled={
                  smoothWorking ||
                  // smoothPlots.length === 0 ||
                  selectedPointsRef.current === undefined ||
                  selectedPointsRef.current.length === 0
                }
              />
              <Tooltip placement="top" title={'Glättung löschen'}>
                <Button
                  loading={smoothWorking}
                  onClick={resetSmoothPlots}
                  icon={<StopOutlined />}
                  disabled={smoothPlots.length === 0}
                />
              </Tooltip>
              <Tooltip placement="top" title={'Glättung anwenden'}>
                <Button
                  loading={smoothWorking}
                  onClick={handleApplySmooth}
                  icon={<CheckSquareOutlined />}
                  disabled={smoothPlots.length === 0}
                />
              </Tooltip>
            </Space>
          </Tabs.TabPane>
        </Tabs>
      </Row>
      <Divider />
      <div
        id="div_plot-wrapper"
        tabIndex={0}
        ref={wrapperRef}
        style={{
          height: 'calc(100% - 180px)',
          width: '100%',
        }}
      >
        <Plot
          ref={plotRef}
          className="plot-style"
          useResizeHandler={true}
          data={[
            {
              name: 'Korrektur',
              x: plots.map((x) => x.x),
              y: plots.map((x) => x.z),
              type: 'scattergl',
              mode: showLines ? 'lines+markers' : 'markers',
              //@ts-ignore
              selected: {
                marker: {
                  color: appSettings.selectedMarkerColor,
                  size: Number.parseFloat(appSettings.selectedMarkerSize),
                },
              },
              marker: {
                color: appSettings.markerColor,
                size: Number.parseFloat(appSettings.markerSize),
                opacity: 1,
              },
              line: {
                color: appSettings.lineColor,
                width: Number.parseFloat(appSettings.lineWidth),
              },
              selectedpoints: selectedPoints,
              //hovertext: "<",
              hoverinfo: 'none',
              hoverlabel: {
                align: 'auto',
                bgcolor: 'transparent',
              },
            },
            {
              name: 'Glättung',
              x: smoothPlots.map((x) => x.x),
              y: smoothPlots.map((x) => x.z),
              type: 'scattergl',
              mode: showLines ? 'lines+markers' : 'markers',
              marker: {
                color: 'green',
                size: Number.parseFloat(appSettings.markerSize),
                opacity: 1,
              },
              line: {
                color: 'blue',
                width: Number.parseFloat(appSettings.lineWidth),
              },
              //hovertext: "<",
              hoverinfo: 'none',
              hoverlabel: {
                align: 'auto',
                bgcolor: 'transparent',
              },
            },
            showOriginalPlot
              ? {
                  name: 'Original',
                  x: originalPlots.map((x) => x.x),
                  y: originalPlots.map((x) => x.z),

                  type: 'scattergl',

                  mode: showLines ? 'lines+markers' : 'markers',
                  marker: {
                    color: 'rgb(0, 110, 255)',
                    size: 2.5,
                    opacity: 0.5,
                  },

                  line: {
                    color: 'rgba(173, 173, 173, 0.8)',
                    width: Number.parseFloat(appSettings.lineWidth),
                  },
                  hoverlabel: {
                    align: 'auto',
                    bgcolor: 'transparent',
                  },
                }
              : {},
          ]}
          layout={{
            autosize: true,
            margin: { r: 0, b: 15, l: 35, t: 0 },
            clickmode: 'event+select',
            dragmode: 'pan',
            hovermode: 'closest',
            plot_bgcolor: appSettings.backgroundColor,
            paper_bgcolor: appSettings.backgroundColor,
            'xaxis.range[0]': plotLayout?.xaxis.range[0],
            'xaxis.range[1]': plotLayout?.xaxis.range[1],
            'yaxis.range[0]': plotLayout?.yaxis.range[0],
            'yaxis.range[1]': plotLayout?.yaxis.range[1],
            xaxis: {
              range: plotLayout?.xaxis.range,
              gridwidth: 1,
              color: appSettings.axisColor,
            },
            yaxis: {
              range: plotLayout?.yaxis.range,
              visible: true,
              gridwidth: 1,
              color: appSettings.axisColor,
            },
            annotations: plotAnnotations,
          }}
          config={{
            scrollZoom: true,
            responsive: true,
            locale: 'de-DE',
            displaylogo: false,
            logging: true,
            doubleClick: false,
            displayModeBar: false,
          }}
          onClick={(e) => handleSetPointSelection(e)}
          //onSelecting={(e) => console.log(e) }
          onDoubleClick={() => console.log(`DBOL click`)}
          onHover={handleHover}
          onRelayout={handlePlotRelayout}
        />
      </div>
      <SettingsDrawer
        isVisible={drawerSettingsVisible}
        onCancel={handleDrawerSettingsCancel}
        onSave={handleDrawerSettingsSave}
      />
      {modalExportContourRender && (
        <ExportContourModal
          isVisible={modalExportContourVisisble}
          onOk={handleFileExported}
          onCancel={() => setModalExportContourVisisble(false)}
          afterClose={() => setModalExportContourRender(false)}
          zCorrectionExecuted={zCorrectionExecuted}
          extendContourRightExecuted={extendContourRightExecuted}
          originalFileName={originalFilename}
          plots={plots}
          header={headerRows}
          footer={footerRows}
        />
      )}
    </>
  );
}

export { PlotterView };
