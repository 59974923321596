import { AuthLevels, IUser } from '@ppulwey/opttocut-library';
import { DatePicker, Divider, Form, Input, Modal, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { checkNumberIfFilled } from '../../utils/formRules';

const { Option } = Select;

export type AddUserModalFormType = {
  email: string;
  permissions: number[];
  validTill?: Date | moment.Moment;
  customerNumber?: string;
  companyName?: string;
};

interface IComponentProps {
  isVisible: boolean;
  onOk: (value: AddUserModalFormType) => Promise<void>;
  onCancel?: () => void;
  userObject?: IUser;
}

export function AddUserModal({
  isVisible,
  onOk,
  onCancel,
  userObject,
}: IComponentProps) {
  const [form] = Form.useForm<AddUserModalFormType>();
  const [busy, setBusy] = useState<boolean>(false);
  const permissionsValue = Form.useWatch('permissions', form);

  useEffect(() => {
    form.setFieldsValue({});
  }, [form]);

  useEffect(() => {
    if (isVisible === false) {
      form.resetFields();
    } else {
      if (userObject !== undefined) {
        form.setFieldsValue({
          permissions: userObject.permissions,
          email: userObject.email,
          companyName: userObject.companyName,
          customerNumber: userObject.customerNumber,
          validTill:
            userObject.validTill === undefined
              ? undefined
              : moment(userObject.validTill).utc(false),
        });
      } else {
        form.setFieldsValue({
          permissions: [],
        });
      }
    }
  }, [isVisible, form]);

  const handleModalOk = () => {
    form.submit();
  };

  const handleFormSubmit = async (val: AddUserModalFormType) => {
    try {
      setBusy(true);

      if (val.permissions.includes(AuthLevels.admin)) {
        delete val.validTill;
      }
      await onOk(val);
    } catch {
    } finally {
      setBusy(false);
    }
  };

  const getAuthLevelOptions = () => {
    const options: JSX.Element[] = [];

    for (const levelName in AuthLevels) {
      if (Object.prototype.hasOwnProperty.call(AuthLevels, levelName)) {
        const levelNumber = AuthLevels[levelName];

        if (isNaN(Number(levelNumber))) {
          continue;
        }

        options.push(
          <Option key={levelName} value={levelNumber}>
            {levelName}
          </Option>
        );
      }
    }

    return options;
  };

  return (
    <Modal
      visible={isVisible}
      onOk={handleModalOk}
      onCancel={onCancel}
      okText="OK"
      cancelText="Abbrechen"
      width={600}
      title="Neuen Nutzer anlegen"
      closable={false}
      okButtonProps={{
        loading: busy,
      }}
      cancelButtonProps={{
        loading: busy,
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        labelCol={{ span: 5 }}
        onFinish={handleFormSubmit}
      >
        <Divider orientation="left">Benutzerdaten</Divider>
        {/* User email */}
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Bitte Email angeben',
            },
          ]}
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>

        {/* Permissions */}
        <Form.Item
          name="permissions"
          label="Berechtigung"
          rules={[
            {
              required: true,
              message: 'Bitte Berechtigung angeben',
            },
          ]}
        >
          <Select mode="multiple" allowClear placeholder="Bitte auswählen">
            {getAuthLevelOptions()}
          </Select>
        </Form.Item>

        {/* Customer number */}
        <Form.Item
          name="customerNumber"
          label="Kundennummer"
          rules={[
            {
              validator: (_, val) => checkNumberIfFilled(val, 5, false),
            },
          ]}
        >
          <Input type={'text'} placeholder="Kundennummer" />
        </Form.Item>

        {/* Company name */}
        <Form.Item name="companyName" label="Firmenname">
          <Input type="text" placeholder="Firmenname" />
        </Form.Item>

        <Divider orientation="left">Lizenz</Divider>
        <Form.Item
          name="validTill"
          label="Gültig bis"
          help={
            permissionsValue && permissionsValue.includes(1)
              ? 'Admin Accounts sind dauerhaft gültig'
              : undefined
          }
          rules={[
            {
              required: permissionsValue ? !permissionsValue.includes(1) : true,
              message: 'Bitte Datum angeben',
            },
          ]}
        >
          <DatePicker
            disabled={permissionsValue ? permissionsValue.includes(1) : false}
            format="L"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
