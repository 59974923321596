
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
//import { customerService } from "../../client";
import { CustomerModel } from "../../Models";
import { RootState } from "../Store";

let customerService:any;
type ReducerState = {
  customers: CustomerModel | null;
  customerAdded: CustomerModel | null;
};

const initialState: ReducerState = {
  customers: null,
  customerAdded: null,
};

// * Thunks
const getCustomers = createAsyncThunk<CustomerModel, void>(
  "customer/getCustomers",
  async (thunkApi) => {
    try {
      const response =
        (await customerService.find()) as CustomerModel;
      return response;
    } catch (error) {
      console.error(`Error getting customers`, error);
      throw error;
    }
  }
);

export const addCustomer = createAsyncThunk<
  any,
  {
    customer: Partial<CustomerModel>;
  },
  { state: RootState }
>("customer/addCustomer", async (payload, thunkApi) => {
  try {
    const result = await customerService.create(payload.customer);
    return result;
  } catch (error) {
    console.error(`Error creating customer`, error);
    throw error;
  }
});

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    resetCustomerAdded: (state) => {
      state.customerAdded = null;
    },
    // resetCustomerAdded: (state, action: PayloadAction<CustomerModel>) => {
    //   state.customerAdded = action.payload;
    // },
  },
  extraReducers: (builder) => {
    // * Get all orders
    builder.addCase(getCustomers.fulfilled, (state, { payload }) => {
      state.customers = payload;
    });

    builder.addCase(getCustomers.rejected, (state, { payload }) => {
      // ! DO ERROR handling here....
    });

    builder.addCase(addCustomer.fulfilled, (state, { payload }) => {
      state.customerAdded = payload;
    });
  },
});

// * Export reducers
export const {resetCustomerAdded} = orderSlice.actions;

// * Export thunks
export { getCustomers };

export default orderSlice.reducer;
